import React from 'react';
import ReactGA from '../../utils/google-analytics';
import Layout from '../../components/layout/Layout';
import Head from '../../components/layout/Head';
import ReviewForm from '../../components/organisms/Form/ReviewForm';
import AuthWrapper from '../../components/layout/AuthWrapper';

const isBrowser = typeof window !== 'undefined';

const ReviewLearnerForm = () => {
  if (isBrowser) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  return (
    <AuthWrapper>
      <Head title="Review Learner Form" />
      <Layout
        section="review"
        breadcrumbs={[
          { link: '/', label: 'Home' },
          { link: '/review-forms', label: 'Review forms' },
          { label: 'Review learner form' },
        ]}
      >
        <ReviewForm userType="learner" isFormApproval />
      </Layout>
    </AuthWrapper>
  );
};

export default ReviewLearnerForm;
